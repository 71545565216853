import React from 'react';
import './Header.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4285F4', // Color azul de Google
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

const Header = () => {
  const handleScroll = (sectionId) => {
    const targetSection = document.querySelector(sectionId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <section className="h-wrapper">
        <div className="flexCenter paddings innerWidth h-container">
          <div className="flexCenter h-menu">
            <a href="#menu-item" onClick={(e) => { e.preventDefault(); handleScroll('#menu-item'); }}>Menú</a>
            <a href="#residencies" onClick={(e) => { e.preventDefault(); handleScroll('#residencies'); }}>Quienes Somos</a>
            <a href="#get-started" onClick={(e) => { e.preventDefault(); handleScroll('#get-started'); }}>Sobre Nosotros</a>
            <a href="#contact-us" onClick={(e) => { e.preventDefault(); handleScroll('#contact-us'); }}>Contáctanos</a>
          </div>
        </div>
      </section>
    </ThemeProvider>
  );
};

export default Header;
