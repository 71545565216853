import React, { useState, useEffect } from 'react';
import './CategoryDetail.css';

const categoryData = {
  melamine: {
    title: 'Melamine',
    description: `
      Nos especializamos en la fabricación de muebles y acabados de alta calidad en melamine. Nuestros servicios abarcan una amplia gama de necesidades de mobiliario, incluyendo:

      <br/><br/><strong>Reposteros de Cocina:</strong> Diseñamos y fabricamos reposteros de cocina personalizados que combinan funcionalidad y estilo. Utilizamos melamine de alta calidad para garantizar durabilidad y facilidad de limpieza.
    `,
    images: ['./melamine3.jpg', './melamine4.jpg', './melamine5.jpg', './melamine6.jpg', './melamine8.jpg'],
    backgroundImage: './mela.png'
  },
  puertas: {
    title: 'Puertas',
    description: `
      Ofrecemos una amplia variedad de puertas para todo tipo de necesidades, desde puertas interiores hasta exteriores. Cada puerta está diseñada con materiales de alta calidad y un acabado impecable.
    `,
    images: ['./puerta1.jpg', './puerta2.jpg', './puerta3.jpg'],
    backgroundImage: './dor.png'
  },
  TechosSolYSombra: {
    title: 'Techo Sol y Sombra',
    description: `
      Proporcionamos soluciones para controlar la luz solar en espacios interiores y exteriores. Desde cortinas hasta toldos, nuestros productos están diseñados para ofrecer la máxima protección y confort.
    `,
    images: ['./solysombra1.jpg', './solysombra2.jpg', './solysombra3.jpg'],
    backgroundImage: './techosolysombra.jpg'
  },
  carpinteriaGeneral: {
    title: 'Carpintería General',
    description: `
      Ofrecemos servicios de carpintería general para una variedad de proyectos, desde la fabricación de muebles personalizados hasta reparaciones y remodelaciones.
    `,
    images: ['./carpinteria1.jpg', './carpinteria2.jpg', './carpinteria3.jpg'],
    backgroundImage: './car.jpg'
  }
};

const CategoryDetail = ({ categoryId }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const category = categoryData[categoryId];

  useEffect(() => {
    if (!category) return;

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % category.images.length);
    }, 4000); // Cambia la imagen cada 4 segundos

    return () => clearInterval(interval);
  }, [category]);

  if (!category) return null;

  return (
    <div className="category-detail">
      <div
        className="background-image"
        style={{ 
          backgroundImage: `url(${category.backgroundImage})`, 
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="overlay"></div>
      </div>
      <div className="category-text">
        <h2>{category.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: category.description }}></p>
      </div>
      <div className="category-image-container">
        {category.images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`${category.title} ${index + 1}`}
            className={`category-image ${index === currentImageIndex ? 'active' : ''}`}
          />
        ))}
        <div className="dots-container">
          {category.images.map((_, index) => (
            <div
              key={index}
              className={`dot ${index === currentImageIndex ? 'active' : ''}`}
              onClick={() => setCurrentImageIndex(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryDetail;
