import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <section className="hero-wrapper">
      {/* Imagen de fondo */}
      <div className="background-image"></div>
      <div className="overlay"></div>
      <div className="paddings innerWidth flexCenter hero-container">
        {/* Logo o ícono en la parte superior */}
        <div className="hero-logo">
          <img src="./logo.png" alt="Logo Carpintería a Domicilio" />
        </div>
        {/* Texto centrado */}
        <div className="hero-content">
          <h1 className="new-amsterdam-regular">
            CARPINTERO <br />
            A DOMICILIO<br />
          </h1>
          <p className="new-amsterdam-regular">
            Transformamos madera en obras de arte. Hecho a mano con dedicación y amor.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
