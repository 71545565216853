import React from 'react';
import './Contact.css';
import { MdCall } from 'react-icons/md';
import { FaWhatsapp, FaInstagram, FaFacebook } from 'react-icons/fa';

const Contact = () => {
    const phoneNumber = '+51939966333';
    const whatsappNumber = '+51939966333';
    const whatsappURL = `https://wa.me/${whatsappNumber}`;
    const callURL = `tel:${phoneNumber}`;
    const instagramURL = 'https://www.instagram.com/tuCuentaInstagram';
    const facebookURL = 'https://www.facebook.com/tuCuentaFacebook';

    return (
        <section className='c-wrapper' id="contact-us">
            <div className="paddings innerWidth flexCenter c-container">
                {/* Lado izquierdo */}
                <div className='c-left'>
                    <span className='primaryText'>CONTACTANOS</span>
                    <div className='contactModes'>
                        <div className='row'>
                            <div className='mode'>
                                <div className='flexStart'>
                                    <div className='icon'>
                                        <MdCall size={25}/>
                                    </div>
                                    <div className='detail'>
                                        <span>Llama Ahora</span>
                                        <span>{phoneNumber}</span>
                                    </div>
                                </div>
                                <a href={callURL} className="button">Llama Ahora</a>
                            </div>
                            
                            <div className='mode'>
                                <div className='flexStart'>
                                    <div className='icon'>
                                        <FaWhatsapp size={25}/>
                                    </div>
                                    <div className='detail'>
                                        <span>WhatsApp</span>
                                        <span>{whatsappNumber}</span>
                                    </div>
                                </div>
                                <a href={whatsappURL} target="_blank" rel="noopener noreferrer" className="button">Envía un WhatsApp</a>
                            </div>
                        </div>

                        {/* Íconos de redes sociales centrados debajo */}
                        <div className='socialIcons'>
                            <a href={instagramURL} target="_blank" rel="noopener noreferrer" className="socialIcon">
                                <FaInstagram size={40} />
                            </a>
                            <a href={facebookURL} target="_blank" rel="noopener noreferrer" className="socialIcon">
                                <FaFacebook size={40} />
                            </a>
                        </div>
                    </div>
                </div>

                {/* Lado derecho */}
                <div className='c-right'>
                    <img src="logo.png" alt="Logo" className='logo-image' />
                </div>
            </div>
        </section>
    );
}

export default Contact;
