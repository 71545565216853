import React from 'react';
import './Menu.css';

const Menu = ({ onCategorySelect }) => {
  const categories = [
    { id: 'melamine', name: 'Melamine', icon: './melamine8.jpg' },
    { id: 'puertas', name: 'Puertas', icon: './puerta.png' },
    { id: 'TechosSolYSombra', name: 'Techos Sol y Sombra', icon: './techo2.jpg' },
    { id: 'carpinteriaGeneral', name: 'Carpintería General', icon: './general.png' },
  ];

  return (
    <section className='c-wrapper' id="menu-item">
    <div className="menu">
      {categories.map(category => (
        <div 
          key={category.id} 
          className="menu-item" 
          onClick={() => onCategorySelect(category.id)}
        >
          <img src={category.icon} alt={category.name} />
          <span>{category.name}</span>
        </div>
      ))}
    </div>
    </section>
  );
};

export default Menu;
